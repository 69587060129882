var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('img',{staticStyle:{"width":"2rem","height":"2rem"},attrs:{"src":"/logo-new.png","alt":"Logo"}}),_c('h2',{staticClass:"brand-text text-success ml-1"},[_vm._v("Habit")])]),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex flex-column align-items-center justify-content-center px-5"},[_c('img',{staticStyle:{"width":"10rem","height":"10rem"},attrs:{"src":"/logo-new.png","alt":"Logo"}}),_c('h6',{staticClass:"mt-4 text-success text-center",staticStyle:{"line-height":"1.5"}},[_vm._v(" "+_vm._s(_vm.$t('message.msgSlogan1'))),_c('br'),_vm._v(_vm._s(_vm.$t('message.msgSlogan2'))+" ")])])]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1 font-weight-bold text-primary",attrs:{"title-tag":"h2"}},[_vm._v(" "+_vm._s(_vm.$t('message.CreateAnAccount'))+" ")]),_c('p',{staticClass:"text-left mt-2"},[_c('span',[_vm._v(_vm._s(_vm.$t('message.AlreadyHaveAnAccount'))+" ")]),_c('b-link',{attrs:{"to":{ name: 'auth-login' }}},[_c('span',{staticClass:"text-primary",staticStyle:{"font-weight":"500","text-decoration":"underline","text-underline-offset":"0.2em"}},[_vm._v("  "+_vm._s(_vm.$t('message.SignIn'))+" ")])])],1),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"register-name"}},[_c('validation-provider',{attrs:{"name":"Name","vid":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-name","name":"register-name","state":errors.length > 0 ? false : null,"placeholder":"johndoe"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('message.Email'),"label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":_vm.$t('message.Email'),"vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false : null,"placeholder":"john@example.com"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"register-password","label":_vm.$t('message.Password')}},[_c('validation-provider',{attrs:{"name":_vm.$t('message.Password'),"vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-password","placeholder":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:" mt-2"},[_c('span',{attrs:{"for":"demoInformation"}},[_vm._v(_vm._s(_vm.$t('message.msgTestInformation')))]),_c('validation-provider',{attrs:{"name":_vm.$t('message.testInformation'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mt-1",attrs:{"id":"demoInformation","label":"title","options":[
                    { title: _vm.$t('message.demoAdm'), value: 'demoUser_adm' }, 
                    { title: _vm.$t('message.demoHealth'), value: 'demoUser_health' }, 
                    { title: _vm.$t('message.demoOperations'), value: 'demoUser_operations' }
                  ],"placeholder":_vm.$t('placeholder.type'),"state":errors.length ? false : null,"reduce":function (option) { return option.value; }},model:{value:(_vm.demoInformation),callback:function ($$v) {_vm.demoInformation=$$v},expression:"demoInformation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.demoInformation === 'demoUser_health')?_c('b-form-group',{staticClass:" mt-2"},[_c('span',{attrs:{"for":"demoInformation"}},[_vm._v(_vm._s(_vm.$t('message.language')))]),_c('validation-provider',{attrs:{"name":_vm.$t('message.language'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{staticClass:"mt-1",attrs:{"id":"demoInformation","label":"title","options":[
                    { title: _vm.$t('message.english'), value: 'english' }, 
                    { title: _vm.$t('message.spanish'), value: 'spanish' }
                  ],"placeholder":_vm.$t('placeholder.language'),"state":errors.length ? false : null,"reduce":function (option) { return option.value; }},model:{value:(_vm.language),callback:function ($$v) {_vm.language=$$v},expression:"language"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-button',{staticClass:"mt-2",attrs:{"variant":"primary","type":"submit","block":"","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t('message.SignUp'))+" ")])],1)]}}])}),(_vm.isLoading)?_c('loading',{attrs:{"active":true,"is-full-page":false,"color":_vm.colors.primary}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }