<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <habit-logo /> -->
        <img src="/logo-new.png" alt="Logo" style="width: 2rem; height: 2rem;">
        <h2 class="brand-text text-success ml-1">Habit</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex flex-column align-items-center justify-content-center px-5">
          <!-- <b-img fluid :src="imgUrl" alt="Register V2" /> -->
          <img src="/logo-new.png" alt="Logo" style="width: 10rem; height: 10rem;">
          <h6 class="mt-4 text-success text-center" style="line-height: 1.5;">
             {{ $t('message.msgSlogan1') }}<br>{{ $t('message.msgSlogan2') }}
          </h6>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold text-primary" title-tag="h2">
            {{ $t('message.CreateAnAccount') }}
          </b-card-title>

          <p class="text-left mt-2">
            <span>{{ $t('message.AlreadyHaveAnAccount') }}  </span>
            <b-link :to="{ name: 'auth-login' }">
              <span class="text-primary" style="font-weight: 500; text-decoration: underline; text-underline-offset: 0.2em;">
                &nbsp;{{ $t('message.SignIn') }}
              </span>
            </b-link>
          </p>

          <!-- <b-card-text class="mb-2">
            Make your app management easy and fun!
          </b-card-text> -->

          <!-- Form -->
          <validation-observer ref="registerForm" #default="{ invalid }">
            <b-form class="auth-register-form mt-2" @submit.prevent="register">
              <!-- Name -->
              <b-form-group label="Name" label-for="register-name">
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="register-name"
                    v-model="name"
                    name="register-name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="johndoe"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Project -->
              <!-- <b-form-group label="Project" label-for="register-project">
                <validation-provider
                  #default="{ errors }"
                  name="Project"
                  vid="project"
                  rules="required"
                >
                  <b-form-input
                    id="register-project"
                    v-model="project"
                    name="register-project"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Project name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group> -->

              <!-- Email -->
              <b-form-group :label="$t('message.Email')" label-for="register-email">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('message.Email')"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    name="register-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Password -->
              <b-form-group label-for="register-password" :label="$t('message.Password')">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('message.Password')"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="register-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="checkbox-1"
                >
                  I agree to
                  <b-link>privacy policy & terms</b-link>
                </b-form-checkbox>
              </b-form-group> -->

              <!-- Test Information -->
              <b-form-group class=" mt-2">
                <span for="demoInformation">{{ $t('message.msgTestInformation') }}</span>
                <validation-provider
                  :name="$t('message.testInformation')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-select
                    class="mt-1"
                    id="demoInformation"
                    v-model="demoInformation"
                    label="title"
                    :options="[
                      { title: $t('message.demoAdm'), value: 'demoUser_adm' }, 
                      { title: $t('message.demoHealth'), value: 'demoUser_health' }, 
                      { title: $t('message.demoOperations'), value: 'demoUser_operations' }
                    ]"
                    :placeholder="$t('placeholder.type')"
                    :state="errors.length ? false : null"
                    :reduce="option => option.value">
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Test information language -->
              <b-form-group v-if="demoInformation === 'demoUser_health'" class=" mt-2">
                <span for="demoInformation">{{ $t('message.language') }}</span>
                <validation-provider
                  :name="$t('message.language')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-select
                    class="mt-1"
                    id="demoInformation"
                    v-model="language"
                    label="title"
                    :options="[
                      { title: $t('message.english'), value: 'english' }, 
                      { title: $t('message.spanish'), value: 'spanish' }
                    ]"
                    :placeholder="$t('placeholder.language')"
                    :state="errors.length ? false : null"
                    :reduce="option => option.value">
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                variant="primary"
                type="submit"
                class="mt-2"
                block
                :disabled="invalid"
              >
                {{ $t('message.SignUp') }}
              </b-button>
            </b-form>
          </validation-observer>

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">or</div>
          </div> -->

          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button variant="facebook" href="javascript:void(0)">
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button variant="twitter" href="javascript:void(0)">
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button variant="google" href="javascript:void(0)">
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button variant="github" href="javascript:void(0)">
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->

          <!-- Loading -->
          <loading
            v-if="isLoading"
            :active="true" 
            :is-full-page="false"
            :color="colors.primary"
          />
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import HabitLogo from "@core/layouts/components/Logo.vue";
import { BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText } from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import vSelect from "vue-select"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { colors } from '@/constants'
import realmConnection from '@/views/habit/realm'

export default {
  components: {
    HabitLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    Loading,
    vSelect
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      name: "",
      userEmail: "",
      password: "",
      required,
      email,
      isLoading: false,
      colors,
      demoInformation: "",
      language: ""
      // status: "",
      // project: "",
      // sideImg: require("@/assets/images/pages/register-v2.svg"),
    };
  },
  setup() {
    const { getItem, createItem, updateItem, ObjectId } = realmConnection()
    
    return {
      getItem,
      createItem,
      updateItem,
      ObjectId
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    // imgUrl() {
    //   if (store.state.appConfig.layout.skin === "dark") {
    //     // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //     this.sideImg = require("@/assets/images/pages/register-v2-dark.svg");
    //     return this.sideImg;
    //   }
    //   return this.sideImg;
    // },
  },
  methods: {
    async register() {
      try {
        const success = await this.$refs.registerForm.validate()
        if (!success) return

        this.isLoading = true

        const demoClientId = process.env.VUE_APP_DEMO_CLIENT_ID

        const payload = {
          name: this.name,
          email: this.userEmail,
          privileges: 'consultant',
        }

        await useJwt.register({ email: payload.email, password: this.password, name: payload.name })

        const logUser = await useJwt.login({ email: payload.email, password: this.password })

        useJwt.setToken(logUser.accessToken);
        useJwt.setRefreshToken(logUser.refreshToken);

        // Get user data
        const userResponse = await this.getItem({ collection: 'user', query: { id: logUser.id } })
        if (!userResponse) throw new Error('User not found')

        const userDocId = userResponse._id

        // Add worker
        const { insertedId: workerId } = await this.createItem({ collection: 'worker', payload: { ...payload, client_id: userDocId } })
        if (!workerId) throw new Error('Worker not created')

        // Update user custom data
        const userPayload = {
          "custom_data.username": payload.name,
          "custom_data.project": (this.demoInformation === 'demoUser_health' && this.language === 'english') ? 'demoUser_health_en' : this.demoInformation,
          "custom_data.role": 'consultant',
          "custom_data.worker_id": workerId,
          "custom_data.ability": [ { action: 'manage', subject: 'all' } ],
          "custom_data.expirationDate": new Date(Date.now() + 15 * 24 * 60 * 60 * 1000),
        }
        const action = { $set: userPayload }
        await this.updateItem({ collection: 'user', query: { id: logUser.id }, action })

        await useJwt.realm.currentUser.refreshCustomData();

        // Update local storage with user data
        const {
          custom_data: { ability, role, username, extras, project = "", worker_id = null, expirationDate },
        } = useJwt.realm.currentUser.customData;
        const { id } = useJwt.realm.currentUser;

        const userData = {
          id,
          fullName: username || "",
          username,
          avatar: require("@/assets/images/avatars/13-small.png"),
          email: this.userEmail,
          role,
          ability,
          extras,
          project: project || "",
          worker_id,
          client: { $oid: userDocId.toString() },
          locations: null,
          expirationDate
        };

        localStorage.setItem("userData", JSON.stringify(userData));

        this.$ability.update(ability || [])
        this.$store.commit('userStore/updateUserData', userData)

        // Get demo client data
        const clientData = await this.getItem({ collection: 'client', query: { _id: this.ObjectId(demoClientId) } })
        if (!clientData) throw new Error('Client not found')
      
        clientData._id = userDocId
        clientData.name = username
        if (this.demoInformation === 'demoUser_health' && this.language === 'english') clientData.default_language = 'en'
        localStorage.setItem('clientData', JSON.stringify(clientData))
      
        // Create new client
        const { insertedId: newClientId } = await this.createItem({ collection: 'client', payload: clientData })
        if (!newClientId) throw new Error('Client not created')
        
        if (clientData.default_language) this.$i18n.locale = clientData.default_language

        const initialRoute = this.$route.query.from || (clientData.default_view_pc_team ? '/apps/pc-team' : clientData.default_view_daily_dialogue ? '/habit/meeting/new' : '/apps/calendar')

        await this.$router.replace(initialRoute)
      
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Welcome ${username || ""}`,
            icon: 'CoffeeIcon',
            variant: 'primary',
            text: `You have successfully signed up as consultant. Now you can start to explore!`,
          },
        })
      } catch (error) {
        console.log(error);

        const errors = {
          password: [],
          email: [],
        }

        if (error?.errorCode === "AccountNameInUse") {
          errors.email = [error.error.replace("name", "Email")];
        }
        this.$refs.registerForm.setErrors(errors);

        // showErrorMessage('Error registering user')
        this.isLoading = false
      }
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
